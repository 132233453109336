import {mapActions, mapGetters, mapMutations} from "vuex";
import mainButton from '@/components/main-button/index.vue'
import legalAccordion from '../../components/legal-accordion/index.vue'
import {minLength, required, email} from "vuelidate/lib/validators";
import createLegal from "@/components/modal-windows/create-legal/index.vue";
import notification from "@/components/modal-windows/notification/index.vue";
//sections


export default {
    name: "user-info",
    components: {
        mainButton,
        legalAccordion,
        createLegal,
        notification
    },
    data() {
        return {
            validationErrors: {},
            legal: true,
            contactsEdit: false,
            infoEdit: false,
            isCreateLegalModal: false,
            // payload: {
            //     oldPassword: '',
            //     newPassword: '',
            //     repeatPassword: '',
            // },
            isIndividualData: false,
            isContactData: false,
            isLegalData: false,
            updateIndividualLoading: false,
            updateContactsLoading: false,
        }
    },
    validations: {
        // payload: {
        //     oldPassword: {
        //         required,
        //         minLength: minLength(8),
        //         maxLength: maxLength(25)
        //     },
        //     newPassword: {
        //         required,
        //         minLength: minLength(8),
        //         maxLength: maxLength(25)
        //     },
        //     repeatPassword: {
        //         required,
        //         minLength: minLength(8),
        //         maxLength: maxLength(25),
        //         sameAsPassword: sameAs('password')
        //     }
        // },
        userInfoPayload: {
            name: {
                required,
                minLength: minLength(2)
            },
            lastName: {
                required,
                minLength: minLength(2)
            },
            middleName: {
                // required,
                minLength: minLength(2)
            }
        },
        userContactsPayload: {
            email: {required, email},
            phone: {
                required,
                minLength: minLength(19)
            },
        }
    },
    created() {
        this.getLegalUsers().then(() => {
        })

    },
    computed: {
        ...mapGetters({
            profileRequestsFlag: 'profile/isLoadingProfile',
            userInfoPayload: 'profile/userInfo',
            userContactsPayload: 'profile/userContacts',
            legalPayloadsArray: 'profile/legalUsers',
            type: 'profile/ownershipTypes',
            isCreatingNewLegalSuccess: 'profile/creatingNewLegalSuccess',
            userDataChanged: 'profile/userDataChanged'
        }),
        nameErrors() {
            let error = [];
            if (!this.$v.userInfoPayload.name.$dirty) {
                return error;
            } else if (!this.$v.userInfoPayload.name.required) {
                error.push(this.$t('validation.required'))
            } else if (!this.$v.userInfoPayload.name.minLength) {
                error.push(this.$t('validation.minLength', {'size': 2}));
            }
            return error;
        },
        lastNameErrors() {
            let error = [];
            if (!this.$v.userInfoPayload.lastName.$dirty) {
                return error;
            } else if (!this.$v.userInfoPayload.lastName.required) {
                error.push(this.$t('validation.required'))
            } else if (!this.$v.userInfoPayload.lastName.minLength) {
                error.push(this.$t('validation.minLength', {'size': 2}));
            }
            return error;
        },
        middleNameErrors() {
            let error = [];
            if (!this.$v.userInfoPayload.middleName.$dirty) {
                return error;
            // } else if (!this.$v.userInfoPayload.middleName.required) {
            //     error.push(this.$t('validation.required'))
            } else if (!this.$v.userInfoPayload.middleName.minLength) {
                error.push(this.$t('validation.minLength', {'size': 2}));
            }
            return error;
        },
        emailErrors() {
            let error = [];
            if (!this.$v.userContactsPayload.email.$dirty) {
                return error;
            } else if (!this.$v.userContactsPayload.email.required) {
                error.push(this.$t('validation.required'))
            } else if (!this.$v.userContactsPayload.email.email) {
                error.push('Некоректний email');
            }
            return error;
        },
        phoneErrors() {
            let error = [];
            if (!this.$v.userContactsPayload.phone.$dirty) {
                return error;
            } else if (!this.$v.userContactsPayload.phone.required) {
                error.push(this.$t('validation.required'))
            } else if (!this.$v.userContactsPayload.phone.minLength) {
                error.push(this.$t('validation.minLength', {'size': 19}));
            }
            return error;
        },
        // oldPasswordErrors() {
        //     let error = [];
        //     if (!this.$v.payload.oldPassword.$dirty) {
        //         return error;
        //     }
        //     if (!this.$v.payload.oldPassword.required) {
        //         error.push(this.$t('validationRequired'))
        //     }
        //     if (!this.$v.payload.oldPassword.minLength) {
        //         error.push(this.$t('validationMin') + '8');
        //     }
        //     if (!this.$v.payload.oldPassword.maxLength) {
        //         error.push(this.$t('validationMax') + '25');
        //     }
        //     if (this.validationErrors.oldPassword) {
        //         this.validationErrors.oldPassword.forEach((row) => {
        //             error.push(row);
        //         })
        //     }
        //     return error;
        // },
        // newPasswordErrors() {
        //     let error = [];
        //     if (!this.$v.payload.newPassword.$dirty) {
        //         return error;
        //     }
        //     if (!this.$v.payload.newPassword.required) {
        //         error.push(this.$t('validationRequired'))
        //     }
        //     if (!this.$v.payload.newPassword.minLength) {
        //         error.push(this.$t('validationMin') + '8');
        //     }
        //     if (!this.$v.payload.newPassword.maxLength) {
        //         error.push(this.$t('validationMax') + '25');
        //     }
        //     if (this.validationErrors.newPassword) {
        //         this.validationErrors.newPassword.forEach((row) => {
        //             error.push(row);
        //         })
        //     }
        //     return error;
        // },
        // repeatPasswordErrors() {
        //     let error = [];
        //     if (!this.$v.payload.repeatPassword.$dirty) {
        //         return error;
        //     }
        //     if (!this.$v.payload.repeatPassword.sameAsPassword) {
        //         error.push(this.$t('validationSameAs'));
        //     }
        //     if (this.validationErrors.repeatPassword) {
        //         this.validationErrors.repeatPassword.forEach((row) => {
        //             error.push(row);
        //         })
        //     }
        //     return error;
        // },

    },
    methods: {

        ...mapMutations({
            setUserFullName: 'profile/SET_USER_FULL_NAME',
            setProfileRequestsFlag: 'profile/PROFILE_LENDING_LOADING',
            setIsCreatedLegal: 'profile/SET_IS_CREATED_LEGAL',
            changeUserData: 'profile/CHANGE_USER_DATA'
        }),
        ...mapActions({
            updateUserData: 'profile/UPDATE_USER_DATA',
            getLegalUsers: 'profile/GET_LEGAL_USERS',
        }),
        closeModal() {
            this.isCreateLegalModal = !this.isCreateLegalModal
            if (this.isCreateLegalModal) {
                document.getElementById('html').classList.add('hide')
            } else {
                document.getElementById('html').classList.remove('hide')
            }

        },
        saveUserData(openModal) {
            const {name, lastName, middleName} = this.userInfoPayload
            this.updateUserData({name, last_name: lastName, middle_name: middleName, ...this.userContactsPayload})
                .then(() => {
                    this.setUserFullName({lastName, name})
                    switch (openModal) {
                        case 'user-info': {
                            this.infoEdit = !this.infoEdit
                            this.isIndividualData = !this.isIndividualData
                            break
                        }
                        case 'user-contacts': {
                            this.contactsEdit = !this.contactsEdit
                            this.isContactData = !this.isContactData
                            break
                        }

                    }

                })
                .catch((e) => {
                    if (e.data.errors.phone[0] === ' phone уже занятий.') {
                        this.$toasted.error(this.$t('personalOffice.personalInfo.validation.phoneAlreadyUses'))
                    }
                    if (e.data.errors.email[0] === ' email уже занятий.') {
                        this.$toasted.error(this.$t('personalOffice.personalInfo.validation.email'))
                    }
                    throw e
                })
                .finally(() => {
                    this.updateIndividualLoading = false
                    this.updateContactsLoading = false
                })
        },
        saveUserInfo() {
            this.$v.userInfoPayload.$touch()
            if (this.$v.$anyError) return
            this.saveUserData('user-info')
            this.updateIndividualLoading = !this.updateIndividualLoading
        },
        saveUserContacts() {
            this.$v.userContactsPayload.$touch()
            if (this.$v.$anyError) return
            this.saveUserData('user-contacts')
            this.updateContactsLoading = !this.updateContactsLoading
        }
    }
}
